<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form">
            <a-form-item label="版本号">
              <a-input v-decorator="['version']" placeholder="请输入版本号"></a-input>
            </a-form-item>
            <a-form-item label="类型">
              <a-select
                class="form-control"
                :options="[
                  {
                    label: 'IOS',
                    value: 'IOS',
                  },
                  {
                    label: 'Android',
                    value: 'Android',
                  },
                ]"
                v-decorator="['versionType']"
                allowClear
                placeholder="请选择类型"
              ></a-select>
            </a-form-item>
            <a-form-item label="状态">
              <a-select
                class="form-control"
                :options="[
                  {
                    label: '启用',
                    value: 1,
                  },
                  {
                    label: '废弃',
                    value: 0,
                  },
                ]"
                v-decorator="['status']"
                allowClear
                placeholder="请选择类型"
              ></a-select>
            </a-form-item>
            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-actions">
          <a-button icon="plus-circle" @click="add">新增</a-button>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            :rowKey="$useRowIndex"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
    <!-- 添加/编辑弹框 -->
    <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk">
      <a-form :form="modal.form" class="flex-form form-label-5">
        <a-form-item label="版本号">
          <a-input
            v-decorator="[
              'version',
              {
                rules: [
                  { required: true, message: '请输入版本号' },
                  { pattern: /^[1-9]\d?(\.([1-9]?\d)){2}$/, message: '版本号格式为：X.Y.Z（1-99.0-99.0-99）' },
                ],
              },
            ]"
            placeholder="请输入版本号"
          ></a-input>
        </a-form-item>
        <a-form-item label="最小版本号">
          <a-input
            v-decorator="[
              'minVersion',
              {
                rules: [{ pattern: /^[1-9]\d?(\.([1-9]?\d)){2}$/, message: '版本号格式为：X.Y.Z（1-99.0-99.0-99）' }],
              },
            ]"
            placeholder="请输入最小版本号"
          ></a-input>
        </a-form-item>

        <a-form-item label="类型">
          <a-radio-group
            v-decorator="[
              'versionType',
              {
                rules: [{ required: true, message: '请选择类型' }],
              },
            ]"
            placeholder="请选择类型"
          >
            <a-radio value="IOS">IOS</a-radio>
            <a-radio value="Android">Android</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="强制更新">
          <a-radio-group
            v-decorator="[
              'forceUpdate',
              {
                rules: [{ required: true, message: '请选择是否强制更新' }],
              },
            ]"
            placeholder="请选择是否强制更新"
          >
            <a-radio :value="true">是</a-radio>
            <a-radio :value="false">否</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="修改内容">
          <a-textarea
            v-decorator="[
              'updateContent',
              {
                rules: [{ required: true, message: '请输入修改内容' }],
              },
            ]"
            placeholder="请输入修改内容"
          ></a-textarea>
        </a-form-item>
        <a-form-item label="下载地址" v-show="modal.form.getFieldValue('versionType') === 'Android'">
          <picture-card-upload
            style="width: 150px; height: 150px"
            :beforeUpload="beforeUpload"
            v-decorator="[
              'downloadUrl',
              {
                rules: [{ required: true, message: '请上传下载地址' }],
              },
            ]"
          ></picture-card-upload>
        </a-form-item>
        <a-form-item label="下载地址" v-show="modal.form.getFieldValue('versionType') === 'IOS'">
          {{ appStoreUrl }}
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'

export default {
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '类型',
        width: '10%',
        align: 'center',
        dataIndex: 'versionType',
      },
      {
        title: '版本号',
        width: '10%',
        align: 'center',
        dataIndex: 'version',
      },
      {
        title: '最小版本号',
        width: '10%',
        align: 'center',
        dataIndex: 'minVersion',
      },
      {
        title: '强制更新',
        width: '10%',
        align: 'center',
        dataIndex: 'forceUpdate',
        customRender: (text) => {
          return (
            <div>
              <span>{text === true ? '是' : '否'}</span>
            </div>
          )
        },
      },
      {
        title: '修改内容',
        width: '15%',
        align: 'center',
        dataIndex: 'updateContent',
      },
      {
        title: '状态',
        width: '12%',
        align: 'center',
        dataIndex: 'status',
        customRender: (text, row, index) => {
          const checked = Number(text) === 1
          return (
            <div>
              <a-switch
                loading={row.switchLoading}
                size="small"
                checked={checked}
                onChange={() => {
                  this.changeStatus(row, Number(!checked), index)
                }}
              />
            </div>
          )
        },
      },
      {
        title: '操作',
        width: '10%',
        align: 'center',
        customRender: (text, record) => {
          return (
            <a
              onClick={() => {
                this.edit(record)
              }}
            >
              修改
            </a>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],
      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },
    }
  },
  computed: {
    typesMap() {
      return {}
    },
    appStoreUrl() {
      return 'https://apps.apple.com/cn/app/万泰数智/id1638851644'
    },
  },
  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const params = this.getPageParams(_pageNo, _pageSize)
        const res = await this.$axios.post('/aie/web/app/version/list', {
          ...params,
        })
        const list = res.data.rows
        const { pageSize, pageNo, totalCount } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    add() {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '新增版本'
    },
    async edit(row) {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '编辑版本'
      this.modal.data = { ...row }
      this.safeSetFieldsValue(this.modal.form, { ...row })
    },
    async changeStatus(row, status, index) {
      this.$set(this.dataSource[index], 'switchLoading', true)
      try {
        await this.$axios.post('/aie/web/app/version/update/status', {
          id: row.id,
          status,
        })
        this.$message.success('操作成功!')
        this.doQuery()
      } catch (e) {}
      this.$set(this.dataSource[index], 'switchLoading', false)
    },
    handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            const { id } = this.modal.data
            const actionUrl = id ? '/aie/web/app/version/update' : '/aie/web/app/version/insert'
            const data = {
              id,
              ...params,
            }
            if (params.versionType === 'IOS') {
              data.downloadUrl = this.appStoreUrl
            }
            await this.$axios.post(actionUrl, data)
            this.$message.success('操作成功!')
            this.modal.visible = false
            this.doQuery()
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    beforeUpload(file) {
      let suffix = ''
      try {
        const flieArr = file.name.split('.')
        suffix = flieArr[flieArr.length - 1].toLowerCase()
      } catch (err) {
        suffix = ''
      }
      if (suffix !== 'apk') {
        this.$message.error('请上传apk文件')
        return false
      }
    },
  },
  async mounted() {
    this.doQuery()
  },
}
</script>

<style lang="less" scoped></style>
