var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-card',{attrs:{"bordered":false}},[_c('div',{staticClass:"queryView"},[_c('div',{staticClass:"queryView-conditions"},[_c('a-form',{attrs:{"layout":"inline","form":_vm.form}},[_c('a-form-item',{attrs:{"label":"版本号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['version']),expression:"['version']"}],attrs:{"placeholder":"请输入版本号"}})],1),_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['versionType']),expression:"['versionType']"}],staticClass:"form-control",attrs:{"options":[
                {
                  label: 'IOS',
                  value: 'IOS',
                },
                {
                  label: 'Android',
                  value: 'Android',
                } ],"allowClear":"","placeholder":"请选择类型"}})],1),_c('a-form-item',{attrs:{"label":"状态"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['status']),expression:"['status']"}],staticClass:"form-control",attrs:{"options":[
                {
                  label: '启用',
                  value: 1,
                },
                {
                  label: '废弃',
                  value: 0,
                } ],"allowClear":"","placeholder":"请选择类型"}})],1),_c('a-form-item',[_c('div',{staticClass:"condition-btns"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.doQuery(1)}}},[_vm._v("查询")]),_c('a-button',{attrs:{"type":"default"},on:{"click":_vm.resetAndQuery}},[_vm._v("重置")])],1)])],1)],1),_c('div',{staticClass:"queryView-actions"},[_c('a-button',{attrs:{"icon":"plus-circle"},on:{"click":_vm.add}},[_vm._v("新增")])],1),_c('div',{staticClass:"queryView-main"},[_c('a-table',{staticClass:"queryView-table",attrs:{"rowKey":_vm.$useRowIndex,"bordered":"","loading":_vm.loading,"columns":_vm.tableColumns,"dataSource":_vm.dataSource,"pagination":false,"size":"middle"}}),_c('base-pagination',{attrs:{"totalCount":_vm.totalCount,"pageNo":_vm.pageNo,"pageSize":_vm.pageSize,"size":"small"},on:{"update:totalCount":function($event){_vm.totalCount=$event},"update:total-count":function($event){_vm.totalCount=$event},"update:pageNo":function($event){_vm.pageNo=$event},"update:page-no":function($event){_vm.pageNo=$event},"update:pageSize":function($event){_vm.pageSize=$event},"update:page-size":function($event){_vm.pageSize=$event},"change":_vm.pageChange}})],1)])]),_c('a-modal',{attrs:{"title":_vm.modal.title},on:{"ok":_vm.handleOk},model:{value:(_vm.modal.visible),callback:function ($$v) {_vm.$set(_vm.modal, "visible", $$v)},expression:"modal.visible"}},[_c('a-form',{staticClass:"flex-form form-label-5",attrs:{"form":_vm.modal.form}},[_c('a-form-item',{attrs:{"label":"版本号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'version',
            {
              rules: [
                { required: true, message: '请输入版本号' },
                { pattern: /^[1-9]\d?(\.([1-9]?\d)){2}$/, message: '版本号格式为：X.Y.Z（1-99.0-99.0-99）' } ],
            } ]),expression:"[\n            'version',\n            {\n              rules: [\n                { required: true, message: '请输入版本号' },\n                { pattern: /^[1-9]\\d?(\\.([1-9]?\\d)){2}$/, message: '版本号格式为：X.Y.Z（1-99.0-99.0-99）' },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"请输入版本号"}})],1),_c('a-form-item',{attrs:{"label":"最小版本号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'minVersion',
            {
              rules: [{ pattern: /^[1-9]\d?(\.([1-9]?\d)){2}$/, message: '版本号格式为：X.Y.Z（1-99.0-99.0-99）' }],
            } ]),expression:"[\n            'minVersion',\n            {\n              rules: [{ pattern: /^[1-9]\\d?(\\.([1-9]?\\d)){2}$/, message: '版本号格式为：X.Y.Z（1-99.0-99.0-99）' }],\n            },\n          ]"}],attrs:{"placeholder":"请输入最小版本号"}})],1),_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'versionType',
            {
              rules: [{ required: true, message: '请选择类型' }],
            } ]),expression:"[\n            'versionType',\n            {\n              rules: [{ required: true, message: '请选择类型' }],\n            },\n          ]"}],attrs:{"placeholder":"请选择类型"}},[_c('a-radio',{attrs:{"value":"IOS"}},[_vm._v("IOS")]),_c('a-radio',{attrs:{"value":"Android"}},[_vm._v("Android")])],1)],1),_c('a-form-item',{attrs:{"label":"强制更新"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'forceUpdate',
            {
              rules: [{ required: true, message: '请选择是否强制更新' }],
            } ]),expression:"[\n            'forceUpdate',\n            {\n              rules: [{ required: true, message: '请选择是否强制更新' }],\n            },\n          ]"}],attrs:{"placeholder":"请选择是否强制更新"}},[_c('a-radio',{attrs:{"value":true}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":false}},[_vm._v("否")])],1)],1),_c('a-form-item',{attrs:{"label":"修改内容"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'updateContent',
            {
              rules: [{ required: true, message: '请输入修改内容' }],
            } ]),expression:"[\n            'updateContent',\n            {\n              rules: [{ required: true, message: '请输入修改内容' }],\n            },\n          ]"}],attrs:{"placeholder":"请输入修改内容"}})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.modal.form.getFieldValue('versionType') === 'Android'),expression:"modal.form.getFieldValue('versionType') === 'Android'"}],attrs:{"label":"下载地址"}},[_c('picture-card-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'downloadUrl',
            {
              rules: [{ required: true, message: '请上传下载地址' }],
            } ]),expression:"[\n            'downloadUrl',\n            {\n              rules: [{ required: true, message: '请上传下载地址' }],\n            },\n          ]"}],staticStyle:{"width":"150px","height":"150px"},attrs:{"beforeUpload":_vm.beforeUpload}})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.modal.form.getFieldValue('versionType') === 'IOS'),expression:"modal.form.getFieldValue('versionType') === 'IOS'"}],attrs:{"label":"下载地址"}},[_vm._v(" "+_vm._s(_vm.appStoreUrl)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }